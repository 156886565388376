/* ..............v............. */
.servicemod button {
  display: block;
  margin: 13px auto;
  min-width: 153px;
}
.servicemod button {
  display: block;
  margin: 20px auto;
  min-width: 153px;
  background-color: #9c27b0;
  color: white;
  font-size: 14px;
  border: 1px solid #9c27b0;
  border-radius: 3px;
  padding: 8px;
  box-shadow: -7px -3px 7px 3px #c4c2c2;
  font-weight: 700;
}
.logoind {
  background-color: #000 !important;
}
.servicemod p {
  font-size: 29px;
  font-weight: bold;
  margin-bottom: 37px;
  margin-top: 18px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}
.sertext {
  font-size: 16px;
  
  /* margin-bottom: 37px; */
  /* margin-top: 18px; */
}

@media (min-width: 991px) and (max-width: 1440px) {
  .edit_service_fee_buyer {
    font-size: 21px !important;
  }
}

.category_list_page .table-bordered td{
  text-align: center;
}